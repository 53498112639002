<player>
  <audio src="https://media.githubusercontent.com/media/GianlucaGuarini/arune/main/src/assets/audio/fx.mp3" loop/>
  <button class={state.isPlaying ? 'playing' : ''} onclick={toggleAudio} title={getButtonTitle()}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48 48" preserveAspectRatio="xMidYMid meet">
      <path if={!state.isPlaying} d="M16 37.85v-28l22 14Zm3-14Zm0 8.55 13.45-8.55L19 15.3Z"/>
      <path if={state.isPlaying} d="M28.25 38V10H36v28ZM12 38V10h7.75v28Z"/>
    </svg>
  </button>

  <script>
    export default {
      state: {
        isPlaying: false
      },
      onMounted() {
        this.player = this.$('audio')
      },
      onUpdated() {
        if (this.state.isPlaying && !this.player.playing) {
          this.player.play()
        } else if (!this.player.playing) {
          this.player.pause()
        }
      },
      getButtonTitle() {
        return this.state.isPlaying ? 'Stop this madness' : 'Give me some vibes'
      },
      toggleAudio() {
        this.update({
          isPlaying: !this.state.isPlaying
        })
      }
    }
  </script>

  <style>
    :host {
      position: fixed;
      bottom: 16px;
      right: 16px;
    }

    button {
      position: relative;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      color: var(--text-color);
      border: 2px solid var(--text-color);
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }

    button:hover, button:focus {
      color: var(--active-color);
      border-color: var(--active-color);
    }

    button::before, button::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      visibility: hidden;
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      z-index: -1;
    }

    button::before {
      background: rgba(255, 255, 255, 0.4);
    }

    button::after {
      background: rgba(255, 255, 255, 0.2);
    }

    button.playing::before, button.playing::after {
      visibility: visible;
    }

    button.playing::before {
      animation: buttonAnimation1 ease-in-out 300ms alternate infinite;
    }

    button.playing::after {
      animation: buttonAnimation2 ease-in-out 500ms alternate infinite;
    }

    svg {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      background: var(--background-color);
    }

    @keyframes buttonAnimation1 {
      from {
        transform: scale(1)
      }
      to {
        transform: scale(1.2)
      }
    }

    @keyframes buttonAnimation2 {
      from {
        transform: scale(1)
      }
      to {
        transform: scale(1.3)
      }
    }
  </style>
</player>
